import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButton } from '@angular/material/button';

import { BUTTON_MAT_VARIANTS } from '@app/utils/constants/button-variants';
import { SVG_ICONS_TYPE } from '@constants';

type IconButtonVariant = 'filled' | 'outlined' | 'text' | 'tonal';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'nm-icon-button',
  templateUrl: './icon-button.component.html',
})
export class IconButtonComponent {
  @Input() testId: string = '';
  @Input() disabled: boolean = false;
  @Input() loading: boolean = false;
  @Input() type: 'button' | 'submit' = 'button';
  @Input() variant: IconButtonVariant = 'filled';
  @Input() pressed: boolean = false;
  @Input() icon: SVG_ICONS_TYPE | null = null;
  @Input() iconColor: string = this.getColorIcon();
  @Input() blurOnClick = true;
  @Input() size = 24;

  @Output() clickButton = new EventEmitter<MouseEvent>();

  get buttonClasses(): string[] {
    const classes = [`nm-${this.variant || 'filled'}-button`];

    const matVariant = BUTTON_MAT_VARIANTS[this.variant];
    if (matVariant) {
      classes.push(`mat-${matVariant}-button`);
    }
    if (this.loading) {
      classes.push('loading');
    }
    if (this.pressed) {
      classes.push('pressed');
    }
    return classes;
  }

  constructor() {}

  onClickButton(e: MouseEvent, input?: MatButton): void {
    if (this.blurOnClick && input) {
      input._elementRef.nativeElement.blur();
    }

    if (this.disabled) {
      return;
    }

    this.clickButton.emit(e);
  }

  getColorIcon() {
    const theme = localStorage.getItem('prefers-color');
    if (theme && theme !== 'system') {
      return localStorage.getItem('prefers-color') === 'dark' ? '#8E9099' : '#44474e';
    } else {
      return localStorage.getItem('system-color') === 'dark' ? '#8E9099' : '#44474e';
    }
  }
}
