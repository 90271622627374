<div *ngIf="showImage" class="layout-picture" [style.background-image]="getImageUrl()">
  <div class="layout-block">
    <div class="layout-store">{{ storeName }}</div>
    <div class="layout-description">{{ storeDescription }}</div>
  </div>
</div>
<div class="layout-title-block">
  <nm-icon-button *ngIf="false" [icon]="icon" [size]="24" variant="text" (clickButton)="openMain()"></nm-icon-button>
  <div class="layout-title">{{ title }}</div>
</div>
