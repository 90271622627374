import { AlertModule } from '@app/shared/component/alert/alert.module';
import { AvatarModule } from '@app/shared/component/avatar/avatar.module';
import { ButtonModule } from '@app/shared/component/button/button.module';
import { CheckboxModule } from '@app/shared/component/checkbox/checkbox.module';
import { ChipModule } from '@app/shared/component/chip/chip.module';
import { ControlHintModule } from '@app/shared/component/control-hint/control-hint.module';
import { ControlLabelModule } from '@app/shared/component/control-label/control-label.module';
import { DividerModule } from '@app/shared/component/divider/divider.module';
import { FormFieldModule } from '@app/shared/component/form-field/form-field.module';
import { IconButtonModule } from '@app/shared/component/icon-button/icon-button.module';
import { InputModule } from '@app/shared/component/input/input.module';
import { RadioModule } from '@app/shared/component/radio/radio.module';
import { SvgIconModule } from '@app/shared/component/svg-icon/svg-icon.module';
import { TextareaModule } from '@app/shared/component/textarea/textarea.module';

export const components = [
  AvatarModule,
  ButtonModule,
  CheckboxModule,
  ChipModule,
  ControlHintModule,
  ControlLabelModule,
  DividerModule,
  FormFieldModule,
  InputModule,
  RadioModule,
  TextareaModule,
  SvgIconModule,
  AlertModule,
  IconButtonModule,
];
