import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { BookingComponent } from '@app/modules/pages/booking/booking.component';
import { RootNavigationRoute } from '@constants';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: RootNavigationRoute.booking,
  },
  {
    path: RootNavigationRoute.booking,
    component: BookingComponent,
    loadChildren: () => import('@app/modules/pages/booking/booking.module').then((m) => m.BookingModule),
  },
  // {
  //   path: RootNavigationRoute.menu,
  //   component: MenuComponent,
  //   loadChildren: () => import('@app/modules/pages/menu/menu.module').then((m) => m.MenuModule),
  // },
  {
    path: '**',
    loadChildren: () => import('@app/modules/error/error.module').then((m) => m.ErrorModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
