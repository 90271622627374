export function stringToHslColor(str: string, saturation: number = 50, lightness: number = 50): string {
  let code = 0;

  for (let i = 0; i < str.length; i += 1) {
    code = str.charCodeAt(i) + ((code << 5) - code);
  }

  const hue = code % 360;

  return `hsl(${hue}, ${saturation % 100}%, ${lightness % 100}%)`;
}
