import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'nm-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainPageComponent {
  constructor() {}

  openPage() {}

  openTelegram() {
    window.open('https://t.me/Absolutpos_bot', '_blank');
  }
}
