import { Injectable } from '@angular/core';

import { mutationCreateOnlineBooking, queryPublicBookingSettings } from '@api';
import { ApiService } from '@core';
import { Mutation, MutationCreateOnlineBookingArgs, MutationResult, Query, QueryPublicBookingSettingsArgs, QueryResult } from '@typings';

@Injectable({
  providedIn: 'root',
})
export class BookingApi {
  constructor(private apiService: ApiService) {}

  onlineBookingConfiguration(variables: QueryPublicBookingSettingsArgs): QueryResult<'publicBookingSettings'> {
    return this.apiService.query<Query<'publicBookingSettings'>, QueryPublicBookingSettingsArgs>({
      query: queryPublicBookingSettings,
      variables,
    });
  }

  createOnlineBooking(variables: MutationCreateOnlineBookingArgs): MutationResult<'createOnlineBooking'> {
    return this.apiService.mutate<Mutation<'createOnlineBooking'>, MutationCreateOnlineBookingArgs>({
      mutation: mutationCreateOnlineBooking,
      variables,
    });
  }
}
