<div [ngClass]="fieldClasses" (click)="onClick($event)" [attr.data-test-id]="testId">
  <div class="content-wrapper">
    <div *ngIf="iconLeft" class="icon-wrapper icon-wrapper-color icon-wrapper-left">
      <nm-svg-icon class="icon-left" [size]="24" [name]="iconLeft"></nm-svg-icon>
    </div>

    <div *ngIf="prefix" class="prefix">{{ prefix }}</div>

    <div class="text-wrapper">
      <ng-content></ng-content>
    </div>

    <div *ngIf="suffix" class="suffix">{{ suffix }}</div>

    <div *ngIf="iconRight" class="icon-wrapper icon-wrapper-color icon-wrapper-right">
      <button type="button" (click)="onRightIconClick($event)" [attr.data-test-id]="'icon-right'">
        <nm-svg-icon class="icon-right" [size]="24" [name]="iconRight"></nm-svg-icon>
      </button>
    </div>

    <ng-content select="[icons]"></ng-content>
  </div>
</div>
