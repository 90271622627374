import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

import { SvgIconModule } from '@app/shared/component/svg-icon/svg-icon.module';

import { AvatarComponent } from './avatar.component';

@NgModule({
  declarations: [AvatarComponent],
  imports: [CommonModule, SvgIconModule, MatButtonModule],
  exports: [AvatarComponent],
})
export class AvatarModule {}
