import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component } from '@angular/core';

import { NotifyService } from '@shared';

@Component({
  selector: 'nm-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeSlideInOut', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateX(100%)' }),
        animate('0.2s ease-in-out', style({ opacity: 1, transform: 'translateX(0)' })),
      ]),
      transition(':leave', [animate('0.2s ease-in-out', style({ opacity: 0, transform: 'translateX(100%)' }))]),
    ]),
  ],
})
export class NotificationsComponent {
  constructor(public notifyService: NotifyService) {}
}
