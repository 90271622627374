<button
  type="button"
  class="nm-avatar"
  [ngStyle]="{
    width: size + 'px',
    height: size + 'px',
    backgroundColor: backgroundColor,
    borderRadius: borderRadius,
    cursor: cursor
  }"
  [disabled]="disabled"
  [attr.data-test-id]="testId"
  (click)="onClickAvatar()"
>
  <span *ngIf="title" class="title {{ typography }}">{{ name }}</span>

  <nm-svg-icon
    *ngIf="!title && !imageLabel && !imageUrl"
    class="icon"
    [name]="icon"
    [size]="iconSize"
    [color]="iconColor"
  ></nm-svg-icon>
  <span *ngIf="imageLabel" class="icon avatar-label">{{ imageLabel }}</span>
  <img
    *ngIf="imageUrl || imageLabel"
    #imageElement
    class="image"
    [src]="imageUrl"
    [width]="size"
    [height]="size"
    (load)="load(imageElement)"
    (error)="error(imageElement)"
    alt="avatar image."
  />
</button>
