import { NgModule } from '@angular/core';

import { MainPageComponent } from '@app/modules/pages/main-page/main-page.component';
import { MainPageRoutingModule } from '@app/modules/pages/main-page/main-page-routing.module';
import { ButtonModule } from '@app/shared/component/button/button.module';
import { IconButtonModule } from '@app/shared/component/icon-button/icon-button.module';
import { SharedModule } from '@shared';

@NgModule({
  declarations: [MainPageComponent],
  imports: [ButtonModule, IconButtonModule, MainPageRoutingModule, SharedModule],
  exports: [MainPageComponent],
})
export class MainPageModule {}
