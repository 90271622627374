import { Injectable } from '@angular/core';

import { BookingApi } from '@app/modules/services/booking/booking.api';
import { NotifyService } from '@shared';
import { MutationCreateOnlineBookingArgs, MutationResult, QueryPublicBookingSettingsArgs, QueryResult } from '@typings';

@Injectable({
  providedIn: 'root',
})
export class BookingStorage {
  constructor(private bookingApi: BookingApi, private notifyService: NotifyService) {}

  onlineBookingConfiguration(variables: QueryPublicBookingSettingsArgs): QueryResult<'publicBookingSettings'> {
    return this.bookingApi.onlineBookingConfiguration(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении настроек для бронирований',
      }),
    );
  }

  createOnlineBooking(variables: MutationCreateOnlineBookingArgs): MutationResult<'createOnlineBooking'> {
    return this.bookingApi.createOnlineBooking(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при создании бронирования',
      }),
    );
  }
}
