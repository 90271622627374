import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { SVG_ICONS_TYPE } from '@constants';
import { ImagesService } from '@shared';

@Component({
  selector: 'nm-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent {
  @Input() icon: SVG_ICONS_TYPE = 'burgerMenu';
  @Input() title: string = '';
  @Input() showImage: boolean = true;
  @Input() storeName: string = '';
  @Input() storeDescription: string = '';

  placeholder = "url('/assets/images/booking-placeholder.webp')";
  gradient = 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.48) 100%), ';

  constructor(private router: Router, public imagesService: ImagesService) {}

  openMain() {
    this.router.navigate(['']);
  }

  getImageUrl() {
    if (this.imagesService.file && Array.isArray(this.imagesService.file)) {
      const url = (this.imagesService.file as [SafeUrl | string, string])[0];
      return this.gradient + `url(${url})`;
    }

    if (this.imagesService.file && this.imagesService.file instanceof File) {
      const url = URL.createObjectURL(this.imagesService.file);
      return this.gradient + `url(${url})`;
    }
    return this.gradient + this.placeholder;
  }
}
