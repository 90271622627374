import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

import { HintVariant } from '@app/shared/component/control-hint/control-hint.component';
import { SVG_ICONS_TYPE } from '@constants';

export type ChipValue = {
  value: string;
  text?: string;
};

@Component({
  selector: 'nm-chips',
  templateUrl: './chips.component.html',
  styleUrls: ['./chips.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipsComponent {
  @Input() values: ChipValue[] | null = null;
  @Input() testId: string = '';
  @Input() label: string = '';
  @Input() showLabelIcon: boolean = false;
  @Input() hint: string = '';
  @Input() hintIcon: SVG_ICONS_TYPE | null = null;
  @Input() required: boolean = false;
  @Input() error: boolean = false;
  @Input() control: FormControl | null = null;

  constructor() {}

  get hintType(): HintVariant {
    return this.error ? 'error' : 'helper';
  }

  getSelected(chip: string) {
    return this.control?.value === chip;
  }

  setSelected(chip: string) {
    this.control?.setValue(chip);
  }
}
