import { ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { HTTP_HEADER } from '@constants';
import { getTraceId } from '@services/api';
import { Context } from '@typings';

export const createTraceIdLink = (): ApolloLink => {
  return setContext(async (_, defaultContext) => {
    const context = defaultContext as Context;
    const { headers } = context;

    return {
      headers: {
        ...headers,
        [HTTP_HEADER.TRACE]: getTraceId(),
      },
    };
  });
};
