import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { RouterModule } from '@angular/router';
import { IMaskModule } from 'angular-imask';

import {
  BackButtonModule,
  ButtonModule,
  CalendarModule,
  CalendarWithInputModule,
  CardModule,
  CustomPopupModule,
  IconModule,
  MyButtonModule,
  NotificationModule,
} from '@nomia/nomia-components-lib';
import {
  NomiaUiButtonModule,
  NomiaUiDropDownModule,
  NomiaUiFormModule,
  NomiaUiIconSpriteModule,
  NomiaUiLibModule,
} from '@nomia/nomia-ui-lib';

import { ChipsComponent } from './component/chips/chips.component';
import { components } from './component';

@NgModule({
  declarations: [ChipsComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NomiaUiLibModule,
    NomiaUiFormModule,
    NomiaUiDropDownModule,
    NomiaUiButtonModule,
    IMaskModule,
    NomiaUiIconSpriteModule.forRoot({ path: 'assets/icons/sprite.svg' }),
    CardModule,
    IconModule,
    ButtonModule,
    CustomPopupModule,
    BackButtonModule,
    NotificationModule,
    PortalModule,
    MyButtonModule,
    CalendarModule,
    CalendarWithInputModule,
    MatAutocompleteModule,
    ...components,
  ],
  exports: [NomiaUiLibModule, NomiaUiIconSpriteModule, IMaskModule, NomiaUiDropDownModule, MyButtonModule, ChipsComponent],
})
export class SharedModule {}
