import { ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { HTTP_HEADER } from '@constants';
import { ApplicationLinkProps, Context } from '@typings';

export const createApplicationLink = ({ config }: ApplicationLinkProps): ApolloLink => {
  return setContext(async (_, defaultContext) => {
    const data = await config.config$;
    const context = defaultContext as Context;

    if (data) {
      const { headers } = context;

      return {
        headers: {
          ...headers,
          [HTTP_HEADER.APP_TYPE]: 'WEB',
          [HTTP_HEADER.APP_VERSION]: data.version,
          [HTTP_HEADER.SCHEMA_VERSION]: data.schema,
        },
      };
    }

    return context;
  });
};
