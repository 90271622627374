import { ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

import { SVG_ICONS, SVG_ICONS_TYPE } from '@constants';

@Component({
  selector: 'nm-svg-icon',
  template: `<ng-content></ng-content>`,
  styles: [
    `
      :host {
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }

      :host ::ng-deep svg {
        fill: currentColor;
        width: 100%;
        height: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SvgIconComponent implements OnChanges {
  @Input() size: number = 24;
  @Input() color: string = 'black';
  @Input() name: SVG_ICONS_TYPE = 'placeholder';
  @Input() testId: string = '';

  constructor(private element: ElementRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['size']) {
      this.element.nativeElement.style.width = `${this.size}px`;
      this.element.nativeElement.style.height = `${this.size}px`;
    }

    if (changes['color']) {
      this.element.nativeElement.style.color = this.color;
    }

    if (changes['name']) {
      const iconName: SVG_ICONS_TYPE = changes['name'].currentValue;

      this.element.nativeElement.innerHTML = SVG_ICONS[iconName] || null;
    }
  }
}
