import { gql } from 'apollo-angular';

export const queryImageById = gql`
  query imageById($id: UUID!) {
    imageById(id: $id) {
      id
      originalFileName
      extension
      imageSizes {
        width
        height
        url
      }
    }
  }
`;

export const mutationUploadImages = gql`
  mutation uploadImages($images: [ImageInput!]!) {
    uploadImages(images: $images) {
      mutationResultList {
        entityId
        errorMessage
        result
      }
    }
  }
`;

export const queryImagesWithSize = gql`
  query imagesWithSize($images: [ImagesSizeInput!]!) {
    imagesWithSize(images: $images) {
      images {
        id
        urls {
          sizeType
          width
          height
          url
        }
      }
    }
  }
`;
