import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';

import { SVG_ICONS_TYPE } from '@constants';
import { StrictDictionary } from '@typings';
import { stringToHslColor } from '@utils';

type TypeSize = '24' | '32' | '40' | '48';
type TypeSizeString = StrictDictionary<string, TypeSize>;
type TypeSizeNumber = StrictDictionary<number, TypeSize>;

const TYPE_TYPOGRAPHY: TypeSizeString = { '24': 'small', '32': 'medium', '40': 'large', '48': 'large' };
const TYPE_SIZE: TypeSizeNumber = { '24': 18, '32': 20, '40': 22, '48': 24 };

@Component({
  selector: 'nm-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent {
  isImageFound: boolean = false;

  @Input() type: 'round' | 'square' = 'round';
  @Input() size: TypeSize = '24';
  @Input() title: string = '';
  @Input() imageUrl: string | SafeUrl = '';
  @Input() imageLabel: string = '';
  @Input() color: string = '';
  @Input() icon: SVG_ICONS_TYPE = 'profile';
  @Input() iconColor: string = '';
  @Input() cursor: string = 'pointer';
  @Input() disabled: boolean = false;
  @Input() testId: string = '';

  @Output() clickAvatar = new EventEmitter<void>();
  @Output() imageLoad = new EventEmitter<void>();
  @Output() imageError = new EventEmitter<void>();

  get borderRadius(): string {
    let radius = '';

    if (this.type === 'round') {
      radius = '50%';
    }

    if (this.type === 'square') {
      radius = '8px';
    }

    if (this.type === 'square' && this.size === '24') {
      radius = '4px';
    }

    return radius;
  }

  get typography(): string {
    return TYPE_TYPOGRAPHY[this.size];
  }

  get iconSize(): number {
    return TYPE_SIZE[this.size];
  }

  get name(): string {
    if (!this.title) {
      return '';
    }

    const splitTitle = this.title.toUpperCase().split(' ');

    if (splitTitle.length > 2) {
      splitTitle.length = 2;
    }

    const [firstName, lastName] = splitTitle;

    return `${firstName.substring(0, 1)}${lastName ? lastName.substring(0, 1) : ''}`;
  }

  get backgroundColor(): string {
    if (this.color) {
      return this.color;
    }

    if (!this.title) {
      return '';
    }

    return stringToHslColor(this.title);
  }

  load(evt: HTMLImageElement): void {
    evt.style.display = 'block';
    this.isImageFound = true;
    this.imageLoad.emit();
  }

  error(evt: HTMLImageElement): void {
    evt.style.display = 'none';
    this.isImageFound = false;
    this.imageError.emit();
  }

  onClickAvatar(): void {
    if (this.disabled) {
      return;
    }

    this.clickAvatar.emit();
  }
}
