import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { GraphQLFormattedError } from 'graphql';
import { BehaviorSubject } from 'rxjs';

import { getFirstNFractionalDigitsRegexp, VALIDATION_ERROR_TYPE } from '@constants';
import { UtilsService } from '@core';
import { ValidationErrorWithKey, ValidationNumberConfigType } from '@typings';

@Injectable({
  providedIn: 'root',
})
export class ValidationErrorsService {
  #validationErrors = new BehaviorSubject<ValidationErrorWithKey[]>([]);
  validationErrors$ = this.#validationErrors.asObservable();

  #validationErrorMessages = {
    [VALIDATION_ERROR_TYPE.required]: () => 'Обязательное поле',
    [VALIDATION_ERROR_TYPE.email]: () => 'Введен некорректный email',
    [VALIDATION_ERROR_TYPE.invalidDate]: () => 'Некорректная дата',
    [VALIDATION_ERROR_TYPE.invalidPhoneNumber]: () => 'Введен некорректный номер телефона (макс. 15 цифр)',
    [VALIDATION_ERROR_TYPE.minlength]: (control: AbstractControl) =>
      `Минимальное количество символов: ${control.getError('minlength').requiredLength}`,
    [VALIDATION_ERROR_TYPE.maxlength]: (control: AbstractControl) =>
      `Максимальное количество символов: ${control.getError('maxlength').requiredLength}`,
    [VALIDATION_ERROR_TYPE.invalidPercentageNumber]: () => 'Допустимое значение от 1 до 100',
    [VALIDATION_ERROR_TYPE.positiveNumber]: () => 'Значение должно быть больше 0',
    [VALIDATION_ERROR_TYPE.invalidCharacters]: () => 'Недопустимые символы',
    [VALIDATION_ERROR_TYPE.max]: (control: AbstractControl) => `Максимальное значение: ${control.getError('max').max}`,
    [VALIDATION_ERROR_TYPE.exactLength]: (control: AbstractControl) =>
      `Допустимое количество символов: ${control.getError('exactLength').requiredLength.join(', ')}`,
  };

  constructor(private utilsService: UtilsService) {}

  setValidationErrors(validationErrorsWithKeys: ValidationErrorWithKey[]): void {
    this.#validationErrors.next(validationErrorsWithKeys);
  }

  getValidationErrors(): ValidationErrorWithKey[] {
    return this.#validationErrors.getValue();
  }

  graphQLErrorToValidationErrors(graphQLError: GraphQLFormattedError): ValidationErrors {
    const errorPath = graphQLError.path;
    const splitErrorPath = errorPath ? errorPath[0].toString().split('.') : '';

    return { [splitErrorPath[splitErrorPath.length - 1]]: graphQLError.message };
  }

  setErrorsForFormControls(validationErrors: ValidationErrors[], formGroup: UntypedFormGroup): void {
    validationErrors.forEach((error) => {
      const key = Object.keys(error)[0];
      const control = formGroup.controls[key];

      if (control) {
        control.setErrors(control?.errors ? { [key]: `${control?.errors[key]}. ${error[key]}` } : error);
      }
    });
  }

  resetErrorsFormControl(formGroup: UntypedFormGroup): void {
    Object.keys(formGroup.controls).forEach((key) => formGroup.controls[key].setErrors(null));
  }

  isControlDirtyAndInvalid(control: AbstractControl): boolean {
    return control.dirty && control.invalid;
  }

  validateField(control: AbstractControl): string {
    if (!control?.invalid || !control?.dirty) return '';

    const controlErrorKeys = Object.keys(control?.errors ? control?.errors : {});

    const errorMessages = Object.values(VALIDATION_ERROR_TYPE).reduce((arr, error) => {
      if (controlErrorKeys.includes(VALIDATION_ERROR_TYPE[error])) {
        arr.push(this.#validationErrorMessages[error](control));
      }
      return arr;
    }, new Array<string>());

    return errorMessages.join('. ');
  }

  markFormControls(form: UntypedFormGroup): void {
    const formControls = form.controls;

    Object.keys(formControls).forEach((key) => {
      this.#markControlAsDirty(formControls[key]);
    });
  }

  #markControlAsDirty<T extends AbstractControl>(control: T): void {
    if (!control) {
      return;
    }

    if (control instanceof FormControl) {
      control.markAsDirty();
      control.markAsTouched();
      control.updateValueAndValidity();
    }

    if (control instanceof FormGroup) {
      Object.keys(control.controls).forEach((key) => {
        this.#markControlAsDirty(control.controls[key]);
      });
    }

    if (control instanceof FormArray) {
      control.controls.forEach((c) => {
        this.#markControlAsDirty(c);
      });
    }
  }

  checkInputNumbers(input: HTMLInputElement, config: ValidationNumberConfigType = {}): string {
    return this.checkStringNumbers(input.value, config);
  }

  checkInputNumbersV2(value: string, config: ValidationNumberConfigType = {}): string {
    return this.checkStringNumbers(value, config);
  }

  checkStringNumbers(str: string, config: ValidationNumberConfigType = {}): string {
    const { decimal } = config;

    if (decimal) {
      const fractional = this.#validateFractional(config.fractional);
      // const _re1 = new RegExp('(\d+[,\.]\d{0,'+ fractional + '}).*', 'g');

      let value = str
        .replace(/[^0-9,.-]/g, '') // remove all except numbers and delimiters
        .replace(/,/g, '.') // replace comma to period
        .replace(/(.+)-/g, '$1') // remove minus if it not first symbol
        .replace(/^(-?)0+(\d)/, '$1$2') // remove zero from start, if another letter comes after
        .replace(/^(-?)(\.)/, '$10$2') // set zero before delimiter, if delimiter comes first
        .replace(/^([^.]*\.)(.*)$/, (a, b, c) => b + c.replace(/\./g, '')); // remove any delimiter if they come after existing one
      // .replace(re1, '$1');

      if (config.isPositive) {
        value = value.replace('-', '');
      }

      if (fractional === undefined) {
        return value;
      }

      return value.replace(getFirstNFractionalDigitsRegexp(fractional), '$1$2'); // leave only `fractional` digits after delimiter

      // const withDelimiterRe = new RegExp(`^\d+((\.|),\d{0,${fractional}}){0,1}$`, 'g');
      // const withDelimiter = withDelimiterRe.test(value);

      // if (withDelimiter) {
      //   return value;
      // }

      // const re = new RegExp(`^(\d+(\.|,)\d{1,${fractional}).*`, 'g');
      // return value.replace(re, '$1');
    }

    return str.replace(/[^0-9]+/g, '');
  }

  checkStringNumbersV2(str: string): string {
    if (str.includes('.')) {
      str = str.replace('.', '');
    }

    if (str.includes(',')) {
      str = str.replace(',', '');
    }

    if (str.includes('-')) {
      str = str.replace('-', '');
    }

    return str.replace(/[^0-9,.-]/g, '');
  }

  #validateFractional(fractional?: number): number | undefined {
    if (fractional === undefined || !Number.isFinite(fractional)) {
      return undefined;
    }

    if (fractional <= 0) {
      return 0;
    }

    return Math.floor(fractional);
  }

  checkInputPhone(input: HTMLInputElement): string {
    return input.value.replace(/[^0-9 ()+-]+/g, '');
  }

  checkPhone(value: string): string {
    return value.replace(/[^0-9 ()+-]+/g, '');
  }

  getValidNumber(value: string, maxInteger: number): string {
    return this.utilsService.truncateInteger(this.checkStringNumbersV2(value), maxInteger);
  }

  checkNumber(value: string, decimal: boolean, fractional: number, maxInteger: number, isPositive: boolean = false): string {
    return this.utilsService.truncateInteger(
      this.checkInputNumbersV2(value, {
        decimal,
        fractional,
        isPositive,
      }),
      maxInteger,
    );
  }
}
