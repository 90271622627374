<div class="nm-chips-control">
  <nm-control-label *ngIf="label" [label]="label" [required]="required" [showIcon]="showLabelIcon"></nm-control-label>

  <div class="chips-div">
    <ng-container *ngFor="let chip of values; let i = index">
      <nm-chip class="chip" [selected]="getSelected(chip.value)" (click)="setSelected(chip.value)">{{
        chip.text || chip.value
      }}</nm-chip>
    </ng-container>
  </div>

  <nm-control-hint *ngIf="hint && hintIcon" [icon]="hintIcon" [label]="hint" [type]="hintType"></nm-control-hint>
</div>
