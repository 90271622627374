import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { SVG_ICONS_TYPE } from '@constants';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'nm-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
})
export class FormFieldComponent {
  @Input() testId: string = '';
  @Input() disabled: boolean = false;
  @Input() error: boolean = false;
  @Input() focused: boolean = false;
  @Input() iconLeft: SVG_ICONS_TYPE | null = null;
  @Input() iconRight: SVG_ICONS_TYPE | null = null;
  @Input() prefix: string = '';
  @Input() suffix: string = '';
  @Input() isAllFieldAction: boolean = false;

  @Output() handleClick = new EventEmitter<MouseEvent>();
  @Output() rightIconClick = new EventEmitter<MouseEvent>();

  private componentClass = 'nm-form-field';

  get fieldClasses(): string[] {
    const classes = [this.componentClass];

    if (this.error) {
      classes.push('error');
    }

    if (this.focused) {
      classes.push('focused');
    }

    if (this.disabled) {
      classes.push(`${this.componentClass}-disabled`);
    }

    return classes;
  }

  constructor() {}

  onClick(event: MouseEvent): void {
    this.handleClick.emit(event);
  }

  onRightIconClick(e: MouseEvent) {
    this.rightIconClick.emit(e);

    if (!this.isAllFieldAction) {
      e.stopPropagation();
    }
  }
}
