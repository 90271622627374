import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AlertModule } from '@app/shared/component/alert/alert.module';

import { NotificationsComponent } from './notifications.component';

@NgModule({
  declarations: [NotificationsComponent],
  imports: [CommonModule, AlertModule],
  exports: [NotificationsComponent],
})
export class NotificationsModule {}
