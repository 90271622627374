<button
  mat-button
  [ngClass]="buttonClasses"
  color="primary"
  [type]="type"
  [disableRipple]="true"
  [disabled]="disabled"
  [attr.data-test-id]="testId"
  (click)="onClickButton($event)"
>
  <div class="content">
    <nm-svg-icon *ngIf="iconLeft" [size]="18" [name]="iconLeft"></nm-svg-icon>
    <ng-content></ng-content>
    <nm-svg-icon *ngIf="iconRight" [size]="18" [name]="iconRight"></nm-svg-icon>
  </div>
  <mat-spinner *ngIf="loading" color="primary" diameter="18"></mat-spinner>
</button>
