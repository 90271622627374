import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconModule } from '../svg-icon/svg-icon.module';

import { ControlHintComponent } from './control-hint.component';

@NgModule({
  declarations: [ControlHintComponent],
  imports: [CommonModule, SvgIconModule],
  exports: [ControlHintComponent],
})
export class ControlHintModule {}
