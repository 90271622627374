import { gql } from 'apollo-angular';

export const fragmentImage = gql`
  fragment Image on Image {
    extension
    id
    imageSizes {
      height
      url
      width
    }
    originalFileName
  }
`;

export const fragmentOnlineBookingConfiguration = gql`
  fragment OnlineBookingConfiguration on OnlineBookingConfiguration {
    acceptanceDescription
    acceptanceIcon {
      ...Image
    }
    acceptanceTitle
    actualUrl
    backgroundImage {
      ...Image
    }
    bookingDescription
    bookingTitle
    permanentUrl
    respectWorkingHoursForBooking
    state
    storeDomain {
      permanentSubdomain
      subdomain
    }
    storeId
    storeName
  }
  ${fragmentImage}
`;

export const queryPublicBookingSettings = gql`
  query publicBookingSettings($subdomain: String!) {
    publicBookingSettings(subdomain: $subdomain) {
      schedules {
        daysOfWeek
        fromTime
        scheduleType
        toTime
      }
      settings {
        ...OnlineBookingConfiguration
      }
    }
  }
  ${fragmentOnlineBookingConfiguration}
`;

export const mutationCreateOnlineBooking = gql`
  mutation createOnlineBooking($subdomain: String!, $input: CreateOnlineBookingInput!) {
    createOnlineBooking(subdomain: $subdomain, input: $input) {
      output {
        comment
        contactName
        contactPhone
        dateStart
        guestCount
        id
        timeStart
      }
      status {
        errorMessage
        result
      }
    }
  }
`;
