import { ApolloLink, GraphQLRequest } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { HTTP_HEADER, REFRESH_TOKEN_OPERATION_EXCEPTIONS } from '@constants';
import { AuthLinkProps, Context } from '@typings';

export const createFingerprintLink = ({ storage }: AuthLinkProps): ApolloLink => {
  return setContext(({ operationName }: GraphQLRequest, defaultContext) => {
    const fingerprint = storage.getFingerprintHeader();
    const context = defaultContext as Context;

    if (operationName && REFRESH_TOKEN_OPERATION_EXCEPTIONS.includes(operationName) && fingerprint) {
      const { headers } = context;

      return {
        headers: {
          ...headers,
          [HTTP_HEADER.FINGERPRINT]: fingerprint,
        },
      };
    }

    return context;
  });
};
