import { NgModule } from '@angular/core';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';

import { cache, createApplicationLink, createFingerprintLink, createRequestLink, createTraceIdLink } from '@api';
import { ApolloClientOptions, ApolloLink, NormalizedCacheObject } from '@apollo/client/core';
import { ConfigService } from '@app/config.service';
import { SessionStorage } from '@services/api';

import { createRetryLink } from './link/retry';

export const apolloClient = (
  httpLink: HttpLink,
  storage: SessionStorage,
  configService: ConfigService,
): ApolloClientOptions<NormalizedCacheObject> => {
  return {
    link: ApolloLink.from([
      createTraceIdLink(),
      createApplicationLink({ config: configService }),
      createFingerprintLink({ storage }),
      createRetryLink(),
      createRequestLink({ httpLink }),
    ]),
    cache,
  };
};

@NgModule({
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: apolloClient,
      deps: [HttpLink, SessionStorage, ConfigService],
    },
  ],
})
export class ApolloModule {}
