import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { InputMaskModule } from '@ngneat/input-mask';

import { ControlHintModule } from '../control-hint/control-hint.module';
import { ControlLabelModule } from '../control-label/control-label.module';
import { FormFieldModule } from '../form-field/form-field.module';

import { InputComponent } from './input.component';

@NgModule({
  declarations: [InputComponent],
  imports: [CommonModule, ControlLabelModule, FormsModule, ControlHintModule, ReactiveFormsModule, FormFieldModule, InputMaskModule],
  exports: [InputComponent],
})
export class InputModule {}
