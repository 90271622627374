<div *ngIf="notifyService.$notifications | async as notifications" class="notifications">
  <nm-alert
    class="notifications__item"
    *ngFor="let alert of notifications"
    [type]="alert.type"
    [title]="alert.title"
    [description]="alert.description"
    [link]="alert.link"
    [linkText]="alert.linkText"
    [isTimer]="true"
    [timer]="alert.timer"
    [isClose]="true"
    (timerIsOver)="notifyService.closeNotification(alert.id)"
    (clickAlert)="notifyService.closeNotification(alert.id)"
    (closeAlert)="notifyService.closeNotification(alert.id)"
    @fadeSlideInOut
  ></nm-alert>
</div>
