import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

import { BUTTON_MAT_VARIANTS, ButtonVariant } from '@app/utils/constants/button-variants';
import { SVG_ICONS_TYPE } from '@constants';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'nm-button',
  templateUrl: './button.component.html',
})
export class ButtonComponent {
  @HostBinding('class.full-width') get isFullWidth() {
    return this.fullWidth;
  }
  @Input() testId: string = '';
  @Input() disabled: boolean = false;
  @Input() loading: boolean = false;
  @Input() type: 'button' | 'submit' = 'button';
  @Input() variant: ButtonVariant = 'filled';
  @Input() fullWidth: boolean = false;
  @Input() iconLeft: SVG_ICONS_TYPE | null = null;
  @Input() iconRight: SVG_ICONS_TYPE | null = null;

  @Output() clickButton = new EventEmitter<MouseEvent>();

  get buttonClasses(): string[] {
    const classes = ['nm-button', `nm-${this.variant || 'filled'}-button`];

    const matVariant = BUTTON_MAT_VARIANTS[this.variant];
    if (matVariant) {
      classes.push(`mat-${matVariant}-button`);
    }
    if (this.loading) {
      classes.push('loading');
    }
    if (this.fullWidth) {
      classes.push('full-width');
    }
    if (this.iconLeft) {
      classes.push('with-left-icon');
    }
    if (this.iconRight) {
      classes.push('with-right-icon');
    }
    return classes;
  }

  constructor() {}

  onClickButton(e: MouseEvent): void {
    if (this.disabled) {
      return;
    }

    this.clickButton.emit(e);
  }

  isFlatButton(): boolean {
    return this.variant === 'filled' || this.variant === 'tonal';
  }
}
