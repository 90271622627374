/* eslint-disable @typescript-eslint/no-explicit-any */
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { ComponentFactory, ComponentFactoryResolver, Inject, Injectable, PLATFORM_ID } from '@angular/core';

import { getFirstNIntegerDigitsRegexp } from '@constants';
import { parsePhoneNumber, uuidv4 } from '@utils';

export type ListsDiff = {
  added: string[];
  removed: string[];
};
@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  isClient: boolean;
  isServer: boolean;

  constructor(@Inject(PLATFORM_ID) private platformId: object, private resolver: ComponentFactoryResolver) {
    this.isClient = isPlatformBrowser(this.platformId);
    this.isServer = isPlatformServer(this.platformId);
  }

  isTouch(): boolean {
    return this.isClient ? 'ontouchstart' in document.documentElement : false;
  }

  createComponent(template: any, componentRef: any, component: any): void {
    template.clear();

    const factory: ComponentFactory<any> = this.resolver.resolveComponentFactory(component);

    componentRef = template.createComponent(factory);
    componentRef.instance.title = 'Hello!';
  }

  setPhone(phone: string): string | null {
    if (phone) {
      phone = phone.replace(/[^+\d]/g, '');

      if (phone.length <= 2) {
        return null;
      } else {
        return phone;
      }
    }

    return phone;
  }

  generateUUID4(): string {
    return uuidv4();
  }

  setParsedPhone(phone: string | null): string | null {
    if (!phone) return phone;
    return String(parsePhoneNumber(phone, 'RU')?.number);
  }

  truncateInteger(str: string, integer: number): string {
    return str.replace(getFirstNIntegerDigitsRegexp(integer), '$1$3'); // leave only `integer` digits before delimiter
  }

  removeSign(strNumber: string): string {
    if (!strNumber) return '';
    return strNumber.replace('-', '');
  }

  getListsDiff(prevList: string[], currentList: string[]): ListsDiff {
    return {
      added: currentList.filter((current) => !prevList.includes(current)),
      removed: prevList.filter((prev) => !currentList.includes(prev)),
    };
  }
}
