export const NOT_PHONE_NUMBER_SPECIFIC_CHARS = /[^0-9 !“№%:,.;()-=+/<>#$%]+/g;

const firstNIntegerDigitsBegin = '^(-?\\d{0,';
const firstNIntegerDigitsEnd = '})([^.,]*)(\\..*)?';

const firstNFractionalDigitsBegin = '^(.*\\.)(\\d{0,';
const firstNFractionalDigitsEnd = '})(.*)';

export const getFirstNIntegerDigitsRegexp = (n: number): RegExp => {
  return new RegExp(firstNIntegerDigitsBegin + n + firstNIntegerDigitsEnd);
};

export const getFirstNFractionalDigitsRegexp = (n: number): RegExp => {
  return new RegExp(firstNFractionalDigitsBegin + n + firstNFractionalDigitsEnd);
};
