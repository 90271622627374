export const ACCESS_TOKEN_KEY = 'access';
export const TIME_SHIFT_KEY = 'shift';

export const SUPER_USER_KEY = 'superuser';
export const REFRESH_TOKEN_KEY = 'refresh';
export const ORG_ID_KEY = 'organizationId';
export const CURRENCY_UNIT_KEY = 'currencyUnit';
export const INVITATION_TOKEN_KEY = 'invitation';
export const SIDEBAR_VIEW_KEY = 'sidebarState';
export const HIDE_SUBSCRITPTION_NOTIFICATION = 'hideSubscriptionNotification';

export const EMPTY_AUTH_HEADER_TOKEN = 'No organization selected';

export const RECONNECTION_ATTEMPTS = 5;
export const CONNECTION_TIMEOUT = 900000;
export const CONNECTION_MIN_TIMEOUT = CONNECTION_TIMEOUT - 20000;
export const CONNECTION_INACTIVITY_TIMEOUT = 40000;
export const REFRESH_TOKEN_DELAY = 60000;

export const REFRESH_TOKEN_OPERATION_EXCEPTIONS = ['refresh', 'auth'];
export const AUTH_TOKEN_OPERATION_EXCEPTIONS = [
  'signIn',
  'login',
  'lostPassword',
  'changeLostPassword',
  ...REFRESH_TOKEN_OPERATION_EXCEPTIONS,
];

export const ERROR_MESSAGE_PAYMENT_REQUIRED = 'Payment required';

export enum ERROR_NETWORK_STATUS {
  UNAUTHORIZED = 401,
  PAYMENT_REQUIRED = 402,
  UNKNOWN_ERROR = 0,
}

export enum HTTP_HEADER {
  FINGERPRINT = 'x-nomia-id',
  ORGANIZATION = 'organizationId',
  TRACE = 'sentry-trace',
  CONTENT_DISPOSITION = 'content-disposition',
  CONTENT_TYPE = 'content-type',
  APP_TYPE = 'appType',
  APP_VERSION = 'appVersion',
  SCHEMA_VERSION = 'schemaVersion',
}
