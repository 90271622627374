<button
  *ngIf="title || description"
  type="button"
  class="nm-alert {{ type }}"
  (click)="onClickAlert($event)"
  (mouseenter)="toggleIsPausedTimer()"
  (mouseleave)="toggleIsPausedTimer()"
>
  <nm-svg-icon *ngIf="isIcon" class="icon" [size]="24" [name]="icon"></nm-svg-icon>

  <span class="content">
    <span *ngIf="title" class="title">{{ title }}</span>

    <span>
      <span *ngIf="description" class="description">{{ description }}</span>

      <ng-container *ngIf="link && linkText"
        >&nbsp;<a class="link" target="_blank" rel="noopener noreferrer" [href]="link">{{ linkText }}</a></ng-container
      >
    </span>
  </span>

  <nm-icon-button
    *ngIf="isClose"
    class="close"
    variant="text"
    icon="close"
    (click)="onCloseAlert($event)"
  ></nm-icon-button>

  <span
    *ngIf="isTimer"
    #timerElement
    class="timer"
    [style.animation-duration]="timer + 's'"
    [style.animation-play-state]="isPausedTimer ? 'paused' : 'running'"
  ></span>
</button>
