import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SafeUrl } from '@angular/platform-browser';
import { Apollo } from 'apollo-angular';
import { firstValueFrom, lastValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { mutationUploadImages, queryImageById, queryImagesWithSize } from '@api';
import { ApolloQueryResult } from '@apollo/client/core';
import { AllImageWithSizes, FileError, Image, ImageInput, ImagesSizeInput, Query, QueryImageByIdArgs, UploadImageResponse } from '@typings';

@Injectable({
  providedIn: 'root',
})
export class ImagesService {
  file: File | [SafeUrl | string, string] | null = null;
  file2: File | [SafeUrl | string, string] | null = null;
  hint: string = '';
  isError: boolean = false;
  control: FormControl<ImageInput | null> | null = null;
  control2: FormControl<ImageInput | null> | null = null;

  constructor(private apollo: Apollo) {}

  getImagesWithSize(images: ImagesSizeInput[]): Observable<ApolloQueryResult<{ imagesWithSize: AllImageWithSizes }>> {
    return this.apollo.watchQuery<{ imagesWithSize: AllImageWithSizes }>({
      query: queryImagesWithSize,
      variables: {
        images,
      },
      fetchPolicy: 'no-cache',
    }).valueChanges;
  }

  uploadImages(images: ImageInput[]): Promise<string> {
    return lastValueFrom(
      this.apollo
        .query<{ uploadImages: UploadImageResponse }>({
          query: mutationUploadImages,
          variables: { images },
          context: { useMultipart: true },
          fetchPolicy: 'no-cache',
        })
        .pipe(map((res) => res.data.uploadImages.mutationResultList[0].entityId)),
    );
  }

  createImageInput(file: File): ImageInput {
    return {
      body: file,
      extension: file.name.split('.').pop(),
      originalFileName: file.name.split('.').shift(),
    };
  }

  setFile(file: File | [SafeUrl | string, string] | null): void {
    this.file = file;
  }

  setFile2(file: File | [SafeUrl | string, string] | null): void {
    this.file2 = file;
  }

  setFileControl(file: File, control: FormControl<ImageInput | null>): void {
    this.file = file;
    this.control = control;
    control.setValue(file ? this.createImageInput(file) : null);
    this.setHint('');
    this.setIsError(false);
  }

  setFile2Control(file: File, control: FormControl<ImageInput | null>): void {
    this.file2 = file;
    this.control2 = control;
    control.setValue(file ? this.createImageInput(file) : null);
    this.setHint('');
    this.setIsError(false);
  }

  setHint(hint: string): void {
    this.hint = hint;
  }

  setIsError(isError: boolean): void {
    this.isError = isError;
  }

  fileValidationError(fileError: FileError): void {
    if (fileError.reason === 'fileTypeNotAllowed') {
      this.setHint(`Разрешенные типы файлов ${fileError.allowed.join(', ')}`);
      this.setIsError(true);
    }

    if (fileError.reason === 'fileLimitExceeded') {
      this.setHint(`Превышен максимально допустимый вес файла`);
      this.setIsError(true);
    }
  }

  imageById(variables: QueryImageByIdArgs): Observable<Image> {
    return this.apollo
      .query<Query<'imageById'>, QueryImageByIdArgs>({
        query: queryImageById,
        variables,
      })
      .pipe(map((res) => res.data.imageById!));
  }

  imageByIdPromise(variables: QueryImageByIdArgs): Promise<Image> {
    return firstValueFrom(
      this.apollo
        .query<Query<'imageById'>, QueryImageByIdArgs>({
          query: queryImageById,
          variables,
        })
        .pipe(map((res) => res.data.imageById!)),
    );
  }
}
