import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import dayjs from 'dayjs';
import { BehaviorSubject, distinctUntilChanged, filter, Observable, pairwise, startWith } from 'rxjs';
import { map } from 'rxjs/operators';

import { SessionStorage } from '@app/api';
import { BookingInfo } from '@app/modules/pages/booking/booking.component';
import { BookingStorage } from '@app/modules/services/booking/booking.storage';
import { ChipValue } from '@app/shared/component/chips/chips.component';
import { DATE_FORMAT_VIEW, MAX_CHARACTERS } from '@constants';
import { ValidationErrorsService } from '@core';
import { ImagesService, NotifyService, phoneValidator } from '@shared';
import { BookingForm, CreateOnlineBookingInput, DayOfWeek, OnlineBookingConfiguration, PublicBookingSettings, Schedule } from '@typings';

@Injectable({
  providedIn: 'root',
})
export class BookingService {
  form: FormGroup<BookingForm> = this.getForm();
  booking$: BehaviorSubject<OnlineBookingConfiguration> = new BehaviorSubject({} as OnlineBookingConfiguration);
  schedules$: BehaviorSubject<Schedule[]> = new BehaviorSubject([] as Schedule[]);
  dateValues$: BehaviorSubject<ChipValue[]> = new BehaviorSubject([] as ChipValue[]);
  timeValues$: BehaviorSubject<ChipValue[]> = new BehaviorSubject([] as ChipValue[]);
  bookingInfo: BehaviorSubject<BookingInfo[]> = new BehaviorSubject([] as BookingInfo[]);

  isDone$ = new BehaviorSubject(false);
  isSubmitDisabled$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  daysOfWeekMap: { [key: number]: string } = {
    0: 'SUNDAY',
    1: 'MONDAY',
    2: 'TUESDAY',
    3: 'WEDNESDAY',
    4: 'THURSDAY',
    5: 'FRIDAY',
    6: 'SATURDAY',
  };

  constructor(
    private fb: FormBuilder,
    public bookingStorage: BookingStorage,
    private validationErrorsService: ValidationErrorsService,
    private notifyService: NotifyService,
    public imagesService: ImagesService,
    public sessionStorage: SessionStorage,
  ) {}

  getForm() {
    return this.fb.group<BookingForm>({
      count: this.fb.control(''),
      date: this.fb.nonNullable.control(''),
      time: this.fb.nonNullable.control(''),
      name: this.fb.nonNullable.control(''),
      phone: this.fb.nonNullable.control(''),
      comment: this.fb.nonNullable.control(''),
    });
  }

  initState(booking: PublicBookingSettings) {
    this.booking$.next(booking.settings);
    this.schedules$.next(booking.schedules || []);
    this.dateValues$.next(this.generateISODateArray());
    this.timeValues$.next(this.getTimeValues());

    if (booking.settings.backgroundImage) {
      const image = booking.settings.backgroundImage;
      if (image && 'imageSizes' in image && image.imageSizes.length && image.imageSizes[0].url) {
        this.imagesService.setFile([image.imageSizes[0].url, image.originalFileName]);
      } else {
        this.imagesService.setFile(null);
      }
    }

    if (booking.settings.acceptanceIcon) {
      const icon = booking.settings.acceptanceIcon;
      if (icon && 'imageSizes' in icon && icon.imageSizes.length && icon.imageSizes[0].url) {
        this.imagesService.setFile2([icon.imageSizes[0].url, icon.originalFileName]);
      } else {
        this.imagesService.setFile2(null);
      }
    }
  }

  initForm() {
    this.form = this.fb.group<BookingForm>({
      count: this.fb.control('2'),
      date: this.fb.nonNullable.control('', Validators.required),
      time: this.fb.nonNullable.control('', Validators.required),
      name: this.fb.nonNullable.control('', [Validators.required, Validators.maxLength(MAX_CHARACTERS.NAME)]),
      phone: this.fb.nonNullable.control('', [Validators.required, Validators.maxLength(MAX_CHARACTERS.PHONE), phoneValidator()]),
      comment: this.fb.nonNullable.control('', [Validators.maxLength(MAX_CHARACTERS.DESCRIPTION)]),
    });

    this.form.controls.date.valueChanges
      .pipe(
        startWith(''),
        distinctUntilChanged(),
        pairwise(),
        filter(([prev, next]) => prev !== next),
      )
      .subscribe(([_, next]) => {
        this.form.controls.time.setValue('');
        this.timeValues$.next(this.getTimeValues(next));
      });
  }

  onlineBookingConfiguration(): Observable<PublicBookingSettings> {
    const subdomain = this.sessionStorage.getSubDomain();
    return this.bookingStorage.onlineBookingConfiguration({ subdomain }).pipe(map((res) => res.data.publicBookingSettings!));
  }

  submitForm() {
    if (this.form.invalid) {
      this.validationErrorsService.markFormControls(this.form);
      this.notifyService.addNotification({
        type: 'alert',
        title: 'Необходимо заполнить обязательные поля',
      });

      return;
    }

    this.#disableForm();
    this.createBooking();
  }

  #disableForm(): void {
    this.form.disable({ emitEvent: false });
    this.isSubmitDisabled$.next(true);
  }

  #enableForm(): void {
    this.form.enable({ emitEvent: false });
    this.isSubmitDisabled$.next(false);
  }

  createBooking() {
    const { count, comment, phone, name, date, time } = this.form.controls;
    const newBooking: CreateOnlineBookingInput = {
      guestCount: Number(count.value),
      timeStart: time.value + ':00',
      dateStart: date.value,
      contactPhone: phone.value,
      contactName: name.value,
      comment: comment.value,
    };

    this.bookingStorage
      .createOnlineBooking({
        subdomain: this.booking$.getValue().storeDomain.subdomain || this.booking$.getValue().storeDomain.permanentSubdomain,
        input: newBooking,
      })
      .subscribe((res) => {
        if (res && res.data?.createOnlineBooking.output) {
          this.isDone$.next(true);
          this.#enableForm();
          const booking = res.data?.createOnlineBooking.output;

          this.bookingInfo.next([
            {
              title: 'Дата:',
              text: booking?.dateStart ? dayjs(booking?.dateStart).format(DATE_FORMAT_VIEW) : '',
            },
            {
              title: 'Время:',
              text: booking?.timeStart || '',
            },
            {
              title: 'Количество гостей:',
              text: String(booking?.guestCount) || '',
            },
            {
              title: 'Имя:',
              text: booking?.contactName || '',
            },
            {
              title: 'Телефон:',
              text: booking?.contactPhone || '',
            },
            {
              title: 'Комментарий:',
              text: booking?.comment || '',
            },
          ]);
        }
      });
  }

  generateISODateArray(): ChipValue[] {
    const dates: ChipValue[] = [];
    const today = new Date();
    const schedules = this.schedules$.getValue();
    const booking = this.booking$.getValue();

    for (let i = 0; i < 31; i++) {
      const currentDate = new Date(today);
      currentDate.setDate(today.getDate() + i);

      const day = currentDate.getDate().toString().padStart(2, '0');
      const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
      const daysOfWeek = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
      const dayOfWeek = daysOfWeek[currentDate.getDay()];

      const dayOfWeekJS = currentDate.getDay();
      const dayOfWeekSchedule = this.daysOfWeekMap[dayOfWeekJS];

      if (
        booking &&
        booking?.respectWorkingHoursForBooking &&
        schedules &&
        schedules.find((s) => s.daysOfWeek?.includes(dayOfWeekSchedule as DayOfWeek))
      ) {
        dates.push({
          value: currentDate.toISOString().split('T')[0],
          text: `${day}.${month} (${dayOfWeek})`,
        });
      }

      if (booking && (!booking?.respectWorkingHoursForBooking || !schedules.length)) {
        dates.push({
          value: currentDate.toISOString().split('T')[0],
          text: `${day}.${month} (${dayOfWeek})`,
        });
      }
    }

    return dates;
  }

  getTimeValues(day?: string): ChipValue[] {
    const schedules = this.schedules$.getValue();
    const booking = this.booking$.getValue();
    const withSchedule = booking && booking?.respectWorkingHoursForBooking && schedules;
    const firstWorkDay = !day ? this.getFirstWorkDay(schedules) : day;
    const today = new Date();

    const times: ChipValue[] = [];
    let intervals: { startHour: number; startMinute: number; endHour: number; endMinute: number }[] = [];

    if (withSchedule && firstWorkDay) {
      const dayOfWeekJS = new Date(firstWorkDay).getDay();
      const dayOfWeekSchedule = this.daysOfWeekMap[dayOfWeekJS];
      const daySchedules = schedules
        .filter((s) => s.daysOfWeek?.includes(dayOfWeekSchedule as DayOfWeek))
        .sort((a, b) => {
          return (a.fromTime || '00:00').localeCompare(b.fromTime || '23:59');
        });

      if (daySchedules.length) {
        daySchedules.forEach((schedule) => {
          let [hoursStr, minutesStr] = schedule.fromTime?.split(':') || ['00', '00'];
          const [hoursStrEnd, minutesStrEnd] = schedule.toTime?.split(':') || ['23', '45'];
          let newHours = '';
          let newMinutes = '';
          if (hoursStrEnd !== '23' && minutesStrEnd !== '45') {
            const date = new Date();
            date.setHours(parseInt(hoursStrEnd));
            date.setMinutes(parseInt(minutesStrEnd));
            date.setMinutes(date.getMinutes() - 15);

            newHours = String(date.getHours()).padStart(2, '0');
            newMinutes = String(date.getMinutes()).padStart(2, '0');
          }

          const isToday =
            today.getFullYear() === new Date(firstWorkDay).getFullYear() &&
            today.getMonth() === new Date(firstWorkDay).getMonth() &&
            today.getDate() === new Date(firstWorkDay).getDate();

          if (isToday) {
            const nowHour = today.getHours();
            const nowMinute = today.getMinutes();

            let startHour = nowHour;
            let startMinute = Math.ceil(nowMinute / 15) * 15;

            if (startMinute === 60) {
              startMinute = 0;
              startHour++;
            }

            hoursStr = Math.max(Number(hoursStr), startHour).toString();
            minutesStr = Number(hoursStr) === startHour ? String(startMinute) : minutesStr;
          }

          intervals.push({
            startHour: parseInt(hoursStr, 10),
            startMinute: parseInt(minutesStr, 10),
            endHour: parseInt(newHours || hoursStrEnd, 10),
            endMinute: parseInt(newMinutes || minutesStrEnd, 10),
          });
        });
      } else {
        intervals.push({ startHour: 0, startMinute: 0, endHour: 23, endMinute: 45 });
      }
    } else {
      intervals.push({ startHour: 0, startMinute: 0, endHour: 23, endMinute: 45 });
      const nowHour = today.getHours();
      const nowMinute = today.getMinutes();

      let startHour = nowHour;
      let startMinute = Math.ceil(nowMinute / 15) * 15;

      if (startMinute === 60) {
        startMinute = 0;
        startHour++;
      }

      intervals = intervals.filter(
        (interval) => interval.endHour > startHour || (interval.endHour === startHour && interval.endMinute >= startMinute),
      );

      const isToday =
        day === undefined ||
        (day &&
          today.getFullYear() === new Date(day).getFullYear() &&
          today.getMonth() === new Date(day).getMonth() &&
          today.getDate() === new Date(day).getDate());

      if (intervals.length) {
        intervals[0].startHour = isToday ? startHour : intervals[0].startHour;
        intervals[0].startMinute = isToday ? startMinute : intervals[0].startMinute;
      }
    }

    intervals.forEach(({ startHour, startMinute, endHour, endMinute }) => {
      while (startHour < endHour || (startHour === endHour && startMinute <= endMinute)) {
        const hour = startHour.toString().padStart(2, '0');
        const minute = startMinute.toString().padStart(2, '0');
        times.push({
          value: `${hour}:${minute}`,
        });

        startMinute += 15;
        if (startMinute === 60) {
          startMinute = 0;
          startHour++;
        }
      }
    });

    return times;
  }

  getFirstWorkDay(schedules: Schedule[]) {
    const today = new Date();
    const currentDay = today.getDay();
    const getDateForNextDay = (daysToAdd: number): Date => {
      const result = new Date(today);
      result.setDate(today.getDate() + daysToAdd);
      return result;
    };

    for (let i = 0; i < 7; i++) {
      const scheduleDayName = this.daysOfWeekMap[(currentDay + i) % 7];

      for (const schedule of schedules) {
        if (schedule.daysOfWeek!.includes(scheduleDayName as DayOfWeek)) {
          const candidateDate = getDateForNextDay(i);
          return candidateDate.toISOString().split('T')[0];
        }
      }
    }

    return '';
  }
}
