import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { SVG_ICONS_TYPE } from '@constants';

@Component({
  selector: 'nm-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertComponent implements OnChanges, AfterViewInit {
  isPausedTimer: boolean = false;

  @Input() type: 'information' | 'success' | 'warning' | 'error' = 'information';
  @Input() icon: SVG_ICONS_TYPE = 'placeholder';
  @Input() title?: string = '';
  @Input() description?: string = '';
  @Input() link?: string = '';
  @Input() linkText?: string = '';
  @Input() isIcon: boolean = false;
  @Input() isTimer: boolean = false;
  @Input() timer?: number = 3.25;
  @Input() isClose: boolean = false;

  @Output() clickAlert = new EventEmitter<void>();
  @Output() closeAlert = new EventEmitter<void>();
  @Output() timerIsOver = new EventEmitter<void>();

  @ViewChild('timerElement') timerElement: ElementRef | undefined;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    const isTimer = changes['isTimer'];

    if (isTimer && !isTimer.firstChange && isTimer.currentValue) {
      this.cdr.detectChanges();

      this.animationListener();
    }
  }

  ngAfterViewInit(): void {
    if ((this.title || this.description) && this.isTimer) {
      this.animationListener();
    }
  }

  animationListener(): void {
    this.timerElement?.nativeElement.addEventListener('animationend', () => this.onTimerIsOver());
  }

  onClickAlert(evt: Event): void {
    evt.stopPropagation();

    this.clickAlert.emit();
  }

  onCloseAlert(evt: Event): void {
    evt.stopPropagation();

    this.closeAlert.emit();
  }

  onTimerIsOver(): void {
    this.isTimer = false;

    this.timerIsOver.emit();
  }

  toggleIsPausedTimer(): void {
    if (this.isTimer) {
      this.isPausedTimer = !this.isPausedTimer;
    }
  }
}
