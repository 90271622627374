import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ControlHintModule } from '../control-hint/control-hint.module';
import { ControlLabelModule } from '../control-label/control-label.module';
import { FormFieldModule } from '../form-field/form-field.module';

import { TextareaComponent } from './textarea.component';

@NgModule({
  declarations: [TextareaComponent],
  imports: [CommonModule, ControlLabelModule, FormsModule, ControlHintModule, ReactiveFormsModule, FormFieldModule, TextFieldModule],
  exports: [TextareaComponent],
})
export class TextareaModule {}
