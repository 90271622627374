import { Alert, AlertType, Notification, NotificationType } from '@app/typings/notifications';

export const notificationToAlert = (notification: Notification): Alert => {
  const type: Record<NotificationType, AlertType> = {
    default: 'information',
    success: 'success',
    information: 'warning',
    alert: 'error',
  };

  return { id: notification.type, type: type[notification.type], description: notification.title };
};
