import { Component, OnInit, Renderer2 } from '@angular/core';

import { SessionStorage } from '@app/api';
import { BookingService } from '@app/modules/services/booking/booking.service';
import { RedirectService } from '@app/shared/service/redirect.service';

@Component({
  selector: 'nm-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'ssp';

  constructor(
    public bookingService: BookingService,
    private renderer: Renderer2,
    private redirectService: RedirectService,
    private sessionStorage: SessionStorage,
  ) {}
  ngOnInit(): void {
    this.bookingService.onlineBookingConfiguration().subscribe(
      (res) => {
        if (res.settings.state === 'DISABLED') {
          this.redirectService.redirectTo404Page();
        } else {
          this.bookingService.initState(res);
          this.bookingService.initForm();
          this.sessionStorage.loader$.next(true);
        }
      },
      () => {
        this.redirectService.redirectTo404Page();
      },
    );

    this.sessionStorage.loader$.subscribe((isLoader) => {
      if (isLoader) {
        let loader = this.renderer.selectRootElement('#loader');
        this.renderer.setStyle(loader, 'display', 'none');
      }
    });
  }
}
