function randomUInt32(): number {
  const min = 0x10000000;
  const max = 0xffffffff;

  return Math.floor(min + Math.random() * (max - min));
}

export function getTraceId(): string {
  const hexUInt64 = `${randomUInt32().toString(16)}${randomUInt32().toString(16)}`;
  return `${hexUInt64}:${hexUInt64}:0:1`;
}
