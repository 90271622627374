import {
  DefinitionNode,
  DocumentNode,
  FragmentDefinitionNode,
  Kind,
  OperationDefinitionNode,
  OperationTypeNode,
  SelectionNode,
  SelectionSetNode,
  VariableDefinitionNode,
} from 'graphql';

import { gql, MutationOptions, QueryOptions, TypedDocumentNode } from '@apollo/client/core';
import { BatchResultKey, Mutation, MutationKeys, OperationBatchVariables, Query, QueryKeys } from '@typings';

type ParsedSelectionNodeType = {
  args: string[];
  structures: string[];
  spreads: string[];
  actionName: string;
};

type Templates = {
  selectionTemplate: string;
  definitionTemplate: string;
};

type FilledTemplates = {
  templatesArguments: string[];
  templatesSelections: string[];
};

type OperationType<T, K> =
  | DocumentNode
  | TypedDocumentNode<Mutation<Extract<T, MutationKeys>>, K>
  | TypedDocumentNode<Query<Extract<T, QueryKeys>>, K>;

export function getBatchOperation<T extends Query<Extract<T, QueryKeys>>, K extends OperationBatchVariables<K[0]>>(
  operation: DocumentNode | TypedDocumentNode<Query<Extract<T, QueryKeys>>, K>,
  variables: K,
): QueryOptions<K, T>;
export function getBatchOperation<T extends Mutation<Extract<T, MutationKeys>>, K extends OperationBatchVariables<K[0]>>(
  operation: DocumentNode | TypedDocumentNode<Mutation<Extract<T, MutationKeys>>, K>,
  variables: K,
): MutationOptions<T, K>;

export function getBatchOperation<
  T extends Mutation<Extract<T, MutationKeys>> | Query<Extract<T, QueryKeys>>,
  K extends OperationBatchVariables<K[0]>,
>(operation: OperationType<T, K>, variables: K): MutationOptions<T, K> | QueryOptions<K, T> {
  const VALUE_PATTERN = '__VALUE__';
  const FRAGMENT_PATTERN = '__FRAGMENT__';
  const RESULT_PATTERN = 'result';

  const batchVariablesObject: OperationBatchVariables<K[0]>[0]['variables'] = {};

  const getParsedFieldNode = (item: SelectionNode): string => {
    if (item.kind === Kind.FIELD) {
      if (item.selectionSet) {
        const selection = createSelectionTemplate(item.selectionSet);

        return `${item.name.value} {${selection}}`;
      }

      return item.name.value;
    } else if (item.kind === Kind.FRAGMENT_SPREAD) {
      return `...${item.name.value}`;
    } else {
      throw Error(`SelectionNode kind parse is not implemented for ${item.kind}`);
    }
  };

  const getParsedSelectionNode = (item: SelectionNode): ParsedSelectionNodeType => {
    if (item.kind === Kind.FIELD) {
      const args: string[] = [];
      const structures: string[] = [];
      const spreads: string[] = [];
      const actionName = item.name.value;

      item.arguments?.map((arg) => {
        const key: string = arg.name.value;
        const value: string = VALUE_PATTERN;

        args.push(`${key}: $${value}`);
      });

      if (item.selectionSet?.kind === Kind.SELECTION_SET) {
        item.selectionSet.selections?.map((selection) => {
          if (selection.kind === Kind.FIELD) {
            structures.push(getParsedFieldNode(selection));
          } else if (selection.kind === Kind.FRAGMENT_SPREAD) {
            spreads.push(`...${selection.name.value}`);
          } else {
            throw Error(`Selection kind parse is not implemented for ${selection.kind}`);
          }
        });
      }

      return { args, structures, spreads, actionName };
    } else {
      throw Error(`Selection parse is not implemented for type ${item.kind}`);
    }
  };

  const createSelectionTemplate = (set: SelectionSetNode): string => {
    const selectionResult: string[] = [];

    set.selections.forEach((item) => {
      const { args, structures, spreads, actionName } = getParsedSelectionNode(item);

      const argsTemplate: string = args.length > 0 ? `(${args.join(',')})` : '';
      const bodyTemplate: string =
        structures.length > 0 || spreads.length > 0 ? `{ ${structures.join('\n')} \n ${spreads.join('\n')} \n}` : '';

      selectionResult.push(`${actionName}${argsTemplate} ${bodyTemplate}`);
    });

    return selectionResult.join('\n');
  };

  const createFragmentTemplate = (node: FragmentDefinitionNode): string => {
    const fragmentResult: string[] = [];

    node.selectionSet.selections.forEach((item) => {
      fragmentResult.push(getParsedFieldNode(item));
    });

    return fragmentResult.join('\n');
  };

  const createDefinitionTemplate = (inputDefs: VariableDefinitionNode[]): { definitionTemplate: string; definitions: string[] } => {
    const definitionResult: string[] = [];
    const definitions: string[] = [];

    inputDefs.forEach((item) => {
      if (item.kind === Kind.VARIABLE_DEFINITION) {
        const name = `$${VALUE_PATTERN}`;
        let type: string = '';

        if (item.type.kind === Kind.NON_NULL_TYPE && item.type.type.kind === Kind.NAMED_TYPE) {
          type = `${item.type.type.name.value}!`;

          definitions.push(item.variable.name.value);
        } else if (item.type.kind === Kind.NAMED_TYPE) {
          type = `${item.type.name.value}`;

          definitions.push(item.variable.name.value);
        } else {
          throw Error(`Type definition parse is not implemented for ${item.type.kind}`);
        }

        definitionResult.push(`${name}: ${type}`);
      } else {
        throw Error(`Definitions parse is not implemented for type ${item.kind}`);
      }
    });

    return {
      definitionTemplate: definitionResult.join(','),
      definitions,
    };
  };

  const fillQueriesTemplates = (v: K, definitions: string[], templates: Templates): FilledTemplates => {
    const { selectionTemplate, definitionTemplate } = templates;

    const templatesArguments: string[] = [];
    const templatesSelections: string[] = [];

    v?.forEach((item, i) => {
      const resultName: BatchResultKey = `${RESULT_PATTERN}${i}`;

      let definiton: string = definitionTemplate;
      let selection: string = selectionTemplate;

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      Object.keys<K[0]>(item)
        .sort((a, b) => definitions.indexOf(String(a)) - definitions.indexOf(String(b)))
        .forEach((key, j) => {
          const name = `${String(key)}_i${i}_v${j}`;

          batchVariablesObject[name] = item[key];

          definiton = definiton.replace(VALUE_PATTERN, name);
          selection = selection.replace(VALUE_PATTERN, name);
        });

      templatesArguments.push(definiton);
      templatesSelections.push(`${resultName}: ${selection}`);
    });

    return { templatesArguments, templatesSelections };
  };

  const queries: string[] = [];
  const replacedFragments: string[][] = [];

  let currentOperationFragmentName: string | null = null;

  const fragments: string[] = [];

  const drainFragments = (operationFragmentName: string | null) => {
    if (operationFragmentName && fragments.length > 0) {
      replacedFragments.push(fragments.map((fragment) => fragment.replace(FRAGMENT_PATTERN, currentOperationFragmentName || '')));
    }

    // drop fragments if new `OPERATION_DEFINITION`
    fragments.length = 0;
  };

  operation.definitions.forEach((defNode) => {
    if (defNode.kind === Kind.OPERATION_DEFINITION) {
      if (defNode.operation === OperationTypeNode.MUTATION || defNode.operation === OperationTypeNode.QUERY) {
        const operationName = defNode.name?.value;

        currentOperationFragmentName = `${operationName}Fragment`;

        const selectionTemplate = createSelectionTemplate(defNode.selectionSet);
        const { definitionTemplate, definitions } = createDefinitionTemplate([...(defNode.variableDefinitions || [])]);

        let queriesArguments: string[] = [];
        let queriesSelections: string[] = [];

        if (defNode.selectionSet.kind === Kind.SELECTION_SET) {
          const { templatesArguments, templatesSelections } = fillQueriesTemplates(variables, definitions, {
            selectionTemplate,
            definitionTemplate,
          });

          queriesArguments = queriesArguments.concat(templatesArguments);
          queriesSelections = queriesSelections.concat(templatesSelections);
        } else {
          throw Error(`SelectionSet parse is not implemented for ${defNode.selectionSet.kind}`);
        }

        const argumentsTemplate = queriesArguments.length ? `(${queriesArguments.join(',')})` : '';

        queries.push(`${defNode.operation} ${operationName}${argumentsTemplate} {
          ${queriesSelections.join('\n')}
        }`);
      } else {
        throw Error(`Batch parse is not implemented for ${defNode.operation}`);
      }

      drainFragments(currentOperationFragmentName);
    } else if (defNode.kind === Kind.FRAGMENT_DEFINITION) {
      const operationName = defNode.name?.value;

      const fragmentTemplate = createFragmentTemplate(defNode);

      fragments.push(`fragment ${operationName} on ${operationName} {\n${fragmentTemplate}}`);
    } else {
      throw Error(`Batch parse is not implemented for ${defNode.kind}`);
    }
  });

  drainFragments(currentOperationFragmentName);

  const batchOperation: string = queries.join('\n') + '\n' + replacedFragments.flat().join('\n');

  const getOperationType = (operation: OperationType<T, K>): OperationTypeNode | undefined => {
    const def = operation.definitions.find((defNode: DefinitionNode) => {
      return (
        defNode.kind === Kind.OPERATION_DEFINITION &&
        (defNode.operation === OperationTypeNode.MUTATION || defNode.operation === OperationTypeNode.QUERY)
      );
    });
    if (!def) throw Error(`OperationType is undefined`);
    return (def as OperationDefinitionNode).operation;
  };

  const operationType = getOperationType(operation);

  if (operationType === OperationTypeNode.MUTATION) {
    return { mutation: gql(batchOperation), variables: batchVariablesObject };
  } else if (operationType === OperationTypeNode.QUERY) {
    return { query: gql(batchOperation), variables: batchVariablesObject };
  } else {
    throw Error(`getBatchOperation() is not implemented for ${operationType} type of operation`);
  }
}
